// A helper function for setting on:keydown handlers to only
// run on certain key(s). Example:
// <div on:keydown={keyhandler('Enter', () => console.log('pressed enter'))} />
export const keyhandler = (keys, handler) => {
  if (!Array.isArray(keys)) {
    keys = [keys]
  }

  return (ev) => {
    if (!keys.includes(ev.key)) {
      return
    }

    handler(ev)
  }
}

// A shortcut for setting a handler as both a click listener and Enter press
// listener. Example:
// <div use:clickOrEnterHandler={(event) => console.log('clicked or pressed enter')} />
// This is useful for avoiding `a11y-click-events-have-key-events` warnings:
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/main/docs/rules/click-events-have-key-events.md
export const clickOrEnterHandler = (node, eventHandlerFn) => {
  let cfn
  let kfn
  function setHandler(eventHandlerFn) {
    if (cfn) node.removeEventListener('click', cfn)
    if (kfn) node.removeEventListener('keydown', kfn)
    if (eventHandlerFn) {
      cfn = eventHandlerFn
      kfn = keyhandler('Enter', eventHandlerFn)
      node.addEventListener('click', cfn)
      node.addEventListener('keydown', kfn)
    }
  }
  setHandler(eventHandlerFn)
  return {
    update: setHandler,
    destroy: () => setHandler(null),
  }
}
